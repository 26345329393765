<template>
  <span class="event-type" :class="{ sm: $mq === 'sm' }">
    {{ event.event_type }}
  </span>
</template>

<script>
export default {
  name: "CgEventTeaserType",

  props: {
    event: {
      type: Object,
      default: undefined
    }
  }
};
</script>

<style lang="scss" scoped>
.event-type {
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  padding-left: 8px;
  &.sm {
    font-family: Roboto;
    font-size: 17.5px;
    font-weight: normal;
    letter-spacing: 0.44px;
    color: #898989;
  }
}
</style>
