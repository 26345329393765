<template>
  <router-link to="/">
    <img
      class="logo"
      :class="{ mobile: $mq === 'sm' || $mq === 'md' }"
      :src="src"
      alt="Cafe Glocksee"
    />
  </router-link>
</template>

<script>
import { getImgPath } from "./../helpers";
export default {
  name: "CgLogo",

  computed: {
    src() {
      return this.$mq !== "sm" && this.$mq !== "md"
        ? getImgPath("cafe-glocksee.png")
        : getImgPath("cafe-glocksee-mobile.png");
    }
  }
};
</script>

<style lang="scss" scoped>
.logo {
  width: 100%;
  max-width: 289px;
  height: auto;
  margin-top: 48px;
  margin-bottom: 13px;
  &.mobile {
    margin-top: 40px;
  }
}
</style>
