<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <ul :class="{ mobile: $mq === 'sm' }">
          <li class="prev">
            <router-link
              :disabled="!prevEventUID(uid)"
              :event="prevEventUID(uid) ? 'click' : ''"
              :to="{
                name: 'event',
                params: {
                  uid: prevEventUID(uid),
                  transitionName: 'slide-right',
                },
              }"
            >
              Vorherige</router-link
            >&nbsp;|
          </li>
          <li class="programm">
            |&nbsp;<router-link :to="'/'">Programm</router-link>&nbsp;|
          </li>
          <li class="next">
            |&nbsp;<router-link
              :disabled="!nextEventUID(uid)"
              :event="nextEventUID(uid) ? 'click' : ''"
              :to="{
                name: 'event',
                params: {
                  uid: nextEventUID(uid),
                  transitionName: 'slide-left',
                },
              }"
              >Nächste</router-link
            >
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CgEventNavigation",

  props: {
    uid: {
      type: String,
      default: undefined
    }
  },

  computed: {
    ...mapGetters(["prevEventUID", "nextEventUID"])
  }
};
</script>

<style lang="scss" scoped>
ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  &.mobile {
    li {
      a {
        font-size: 16px;
      }
    }
  }
}
li {
  list-style-type: none;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 18px;
  a {
    &[disabled='disabled'] {
      color: #808080;
      opacity: 0.5;
      &:hover {
        text-decoration: none;
        cursor: default;
      }
    }
  }
}
</style>
