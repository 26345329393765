<template>
  <div>
    <div id="event" v-if="event">
      <cg-event-detail id="event-detail" :event="event.data" />
      <cg-event-navigation id="event-navigation" :uid="uid" />
      <cg-misc />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import CgEventDetail from "../components/event/cg-event-detail";
import CgEventNavigation from "../components/event/cg-event-navigation";
import CgMisc from "./../components/misc/shared/cg-misc";

export default {
  name: "Event",

  components: {
    CgEventDetail,
    CgEventNavigation,
    CgMisc
  },

  data: () => ({
    uid: undefined
  }),

  computed: {
    ...mapGetters(["getEventByUID"]),

    event() {
      return this.getEventByUID(this.uid);
    }
  },

  watch: {
    event(newValue) {
      if (newValue) {
        if (this.event.data.stage_image) {
          this.SET_STAGE_IMAGE(this.event.data.stage_image);
        } else if (this.event.data.teaser_image) {
          this.SET_STAGE_IMAGE(this.event.data.teaser_image);
        }
        if (this.event.data.sponsor_image) {
          this.SET_STAGE_SPONSOR_IMAGE(this.event.data.sponsor_image);
        }
      }
    }
  },

  methods: {
    ...mapMutations(["SET_STAGE_IMAGE", "SET_STAGE_SPONSOR_IMAGE"])
  },

  created() {
    this.uid = this.$route.params.uid;
  },

  beforeDestroy() {
    this.SET_STAGE_SPONSOR_IMAGE(undefined);
  }
};
</script>

<style lang="scss" scoped>
[id='event-detail'] {
  margin-bottom: 32px;
}
[id='event-navigation'] {
  margin-bottom: 80px;
}
</style>
