<template>
  <div v-if="url" class="wrapper">
    <img
      class="image"
      :src="`${url}&w=730`"
      :alt="image.alt ? image.alt : ''"
      :srcset="`
        ${url}&w=730 1x,
        ${url}&w=1460 2x,
        ${url}&w=2190 3x
      `"
    />
    <div v-if="url || image.alt" class="text">
      <span v-if="image.alt">{{ image.alt }}</span>
      <span v-if="image.copyright">{{ image.copyright }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CgContentImage",

  props: {
    image: {
      type: Object,
      default: undefined
    }
  },

  computed: {
    url() {
      return this.image && this.image.url ? this.image.url : undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 840px;
  text-align: center;
}

.image {
  width: 100%;
  height: auto;
}

.text {
  margin-top: 3px;
  font-size: 11px;
  font-weight: 300;
  color: #898989;
  text-align: left;
}
</style>
