<template>
  <b-row class="teaser" :class="{ mobile: $mq === 'sm' }">
    <b-col cols="12" class="teaser-meta">
      <b-row>
        <cg-event-teaser-header-mobile v-if="$mq === 'sm'" :event="event" />
        <template v-else>
          <b-col cols="4" class="event-type">
            <cg-event-type :event="event" />
          </b-col>
          <b-col cols="5" class="event-date">
            <cg-event-teaser-date :event="event" />
          </b-col>
          <b-col cols="3" class="event-quicklinks-container">
            <cg-event-teaser-quicklinks :event="event" />
          </b-col>
        </template>
      </b-row>
    </b-col>
    <b-col :cols="$mq | mq({ sm: 12, md: 4 })" class="event-teaser">
      <img
        v-if="hasTeaserImage"
        class="teaser-image"
        :src="`${event.teaser_image.url}&w=364`"
      />
      <img
        v-if="hasSponsorImage"
        class="sponsor-image"
        :src="`${event.sponsor_image.url}&w=364`"
      />
      <div
        v-if="hasTeaserImage && event.teaser_image.alt"
        class="event-teaser-image-text"
      >
        <span>{{ event.teaser_image.alt }}</span>
      </div>
    </b-col>
    <b-col :cols="$mq | mq({ sm: 12, md: 8 })" class="event-data">
      <h2>{{ $prismic.richTextAsPlain(event.title) }}</h2>

      <div class="teaser-text">
        <cg-event-text :uid="uid" :text="this.event.text" :teaser="true" />
      </div>

      <div class="support" v-if="hasSupport">
        Support:
        <span v-for="(band, index) in support" :key="`band-${index}`">
          {{ band.name }}
        </span>
      </div>
      <cg-event-teaser-quicklinks
        v-if="hasQuicklinks && $mq === 'sm'"
        class="event-quicklinks-container"
        :event="event"
      />
      <div class="event-infos-wrapper">
        <cg-event-infos
          v-if="hasInfos"
          class="event-infos"
          :infos="event.info_list"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import CgEventInfos from "./../shared/cg-event-infos";
import CgEventText from "./../shared/cg-event-text";
import CgEventType from "./../shared/cg-event-type";
import CgEventTeaserDate from "./cg-event-teaser-date";
import CgEventTeaserHeaderMobile from "./shared/cg-event-teaser-header-mobile";
import CgEventTeaserQuicklinks from "./cg-event-teaser-quicklinks";

export default {
  name: "CgEventTeaser",

  components: {
    CgEventInfos,
    CgEventText,
    CgEventType,
    CgEventTeaserDate,
    CgEventTeaserHeaderMobile,
    CgEventTeaserQuicklinks
  },

  props: {
    event: {
      type: Object,
      default: null
    },

    uid: {
      type: String,
      default: null
    }
  },

  computed: {
    hasTeaserImage() {
      return !!this.event.teaser_image.url;
    },

    hasSponsorImage() {
      return !!this.event.sponsor_image.url;
    },

    hasQuicklinks() {
      return this.event.facebook.url || this.event.tickets.url ? true : false;
    },

    hasInfos() {
      return this.event.info_list.length > 0;
    },

    hasSupport() {
      return this.support !== undefined && this.support.length > 0
        ? true
        : false;
    },

    support() {
      return this.event.bands.filter(
        band => band.role === "Support" && band.name
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.teaser {
  padding-bottom: 70px;

  &.mobile {
    padding-bottom: 48px;

    &.row {
      padding-top: 2px;
      border-top: 1px solid #c1c1c1;
      .list-complete-item:first-child & {
        padding-top: 4px;
        border-top: none;
      }
    }

    .teaser-meta {
      border: none;
      padding-left: 15px;
      padding-right: 15px;
    }
    .event-teaser {
      padding-left: 15px;
      margin-bottom: $baseline;
    }

    h2 {
      margin-top: 0;
      font-size: 24px;
      letter-spacing: 0.72px;
      color: #000000;
      line-height: 1;
      margin-bottom: 4px;
    }

    .event-teaser-image-text {
      left: 0px;
      font-size: 10px;
    }

    .teaser-image {
      margin-bottom: 0;
    }

    .event-data {
      padding-left: 15px;
    }

    .teaser-text {
      margin-bottom: $baseline / 2;
      & + .support {
        margin-top: 0;
      }
    }

    .support {
      margin-bottom: 8px;
      padding-top: 8px;
      border-top: 1px solid #000;
      padding-bottom: 8px;
      border-bottom: 1px solid #000;
    }

    .event-infos {
      width: 100%;
      margin-bottom: $baseline;
      /deep/ li {
        display: block;
        list-style: none;
        margin: 0 0 $baseline / 2 0;
        padding-bottom: $baseline / 2;
        border-bottom: 1px solid #000;

        font-size: 17px;
        font-stretch: normal;
        letter-spacing: 0.34px;
        text-align: left;
        color: #000000;
        &:after {
          content: '';
        }
        &:last-child {
          border-bottom: 1px solid #000;
        }
      }
    }

    .event-quicklinks-container {
      justify-content: flex-start;
      border-bottom: 1px solid #000;
      margin: 0 0 $baseline / 2 0;
      padding-bottom: $baseline;
    }
  }
}

.teaser-meta {
  border-top: 1px solid #000;
  border-bottom: 1px solid #c1c1c1;
  padding: 2px 0 2px 0;
}

h2 {
  margin: 8px 0 2px 0;
  font-family: 'Work Sans', sans-serif;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.93px;
}

.event-teaser {
  position: relative;
  padding-left: 0;
}

.event-teaser-image-text {
  position: absolute;
  top: 0;
  left: -16px;
  height: auto;
  transform-origin: 0 0;
  transform: rotate(-90deg) translateX(-100%) translateY(0%);
  font-size: 11px;
  color: #898989;
}

.teaser-image {
  width: 100%;
  height: auto;
}

.sponsor-image {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #c1c1c1;
}

.teaser-text {
  margin-bottom: 18px;
  margin-right: 8.33333%; // 1 col
  & + .support {
    margin-top: -18px;
  }
}

.support {
  font-weight: bold;
  color: #898989;
  font-style: italic;
  margin-bottom: 8px;
}

.event-type,
.event-date,
.event-quicklinks-container {
  display: flex;
  align-items: center;
}

.event-type {
  padding-left: 8px;
  text-transform: uppercase;
}

.event-quicklinks-container {
  justify-content: flex-end;
}

// .teaser-text,
// .teaser-text /deep/ .event-text,
// .more {
//   font-size: 18.5px;
// }

.event-data {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.event-infos-wrapper {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.event-infos {
  margin: 0;
  padding: 0;
  /deep/ li {
    margin: 0;
    padding: 0;
    display: inline;
    font-size: 18px;
    text-align: left;
    color: #010101;

    &:after {
      padding: 0 2px 0 2px;
      content: '|';
    }
    &:last-child:after {
      content: '';
    }
  }
}

.support-list {
  font-size: 18.5px;
  font-style: italic;
  letter-spacing: 0.28px;
  text-align: justify;
  color: #595959;
}
</style>
