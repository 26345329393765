<template>
  <b-col cols="12" class="teaser-head">
    <cg-event-type class="event-type" :event="event" />
    <cg-event-teaser-date class="event-date" :event="event" />
  </b-col>
</template>

<script>
import CgEventTeaserDate from "./../cg-event-teaser-date";
import CgEventType from "./../../shared/cg-event-type";

export default {
  name: "CgEbentTeaserHeaderMobile",

  components: {
    CgEventTeaserDate,
    CgEventType
  },

  props: {
    event: {
      type: Object,
      default: undefined
    }
  }
};
</script>

<style lang="scss" scoped>
.teaser-head {
  margin-top: 0;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  .event-type {
    &:after {
      color: #000;
      padding: 0 $baseline / 2 0 $baseline / 2;
      content: '|';
    }
  }
}

.event-type,
.event-date {
  display: flex;
  align-items: center;
}
</style>
