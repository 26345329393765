import Vue from "vue";
import prismicJS from "prismic-javascript";
import prismicDOM from "prismic-dom";
import linkResolver from "./prismic/link-resolver";
import htmlSerializer from "./prismic/html-serializer";
// import BootstrapVue from "bootstrap-vue";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
import VueMq from "vue-mq";

import "./scss/styles.scss";

import AppPage from "./app-page.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.use(VueMq, {
  breakpoints: {
    sm: 540,
    md: 720,
    lg: 960,
    xl: Infinity
  },
  defaultBreakpoint: "sm"
});

// Vue.use(PrismicVue, {
//   endpoint: "https://cafe-glocksee.cdn.prismic.io/api/v2",
//   linkResolver,
//   htmlSerializer
// });

Vue.use(
  {
    install: function(Vue, options = {}) {
      Vue.prototype.$prismic = prismicJS;
      Vue.prototype.$prismic.endpoint = options.endpoint;
      Vue.prototype.$prismic.linkResolver = options.linkResolver;
      Vue.prototype.$prismic.htmlSerializer = options.htmlSerializer;
      Vue.prototype.$prismic.client = prismicJS.client(
        options.endpoint,
        options.apiOptions
      );
      Vue.prototype.$prismic.richTextAsPlain = function(field) {
        if (!field) {
          return "";
        }
        return prismicDOM.RichText.asText(field);
      };
      Vue.prototype.$prismic.richTextAsHTML = function(field) {
        if (!field) {
          return "";
        }
        return prismicDOM.RichText.asHtml(field);
      };
    }
  },
  {
    endpoint: "https://cafe-glocksee.cdn.prismic.io/api/v2",
    linkResolver,
    htmlSerializer
  }
);

// Vue.use(BootstrapVue);

import { LayoutPlugin, NavbarPlugin } from "bootstrap-vue";
Vue.use(LayoutPlugin);
Vue.use(NavbarPlugin);

router.afterEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    left: 0
  });
});

new Vue({
  router,
  store,
  render: h => h(AppPage)
}).$mount("#app");
