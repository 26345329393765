<template>
  <div class="event-boilerplate">
    <span v-html="$prismic.richTextAsHTML(this.text)" />
  </div>
</template>

<script>
export default {
  name: "CgEventBoilerplate",

  props: {
    text: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.event-boilerplate {
  font-size: 15px;
  font-style: italic;
  color: #898989;
}
</style>
