<template>
  <b-container v-if="event" id="event-detail" :class="{ mobile: $mq === 'sm' }">
    <b-row v-if="$mq === 'sm'">
      <cg-event-teaser-header-mobile :event="event" />
    </b-row>
    <b-row v-if="$mq === 'sm'">
      <b-col :cols="$mq | mq({ sm: 12, md: 4 })" class="event-teaser">
        <img
          v-if="hasTeaserImage"
          class="teaser-image"
          :src="`${event.teaser_image.url}&w=364`"
        />
        <img
          v-if="hasSponsorImage"
          class="sponsor-image"
          :src="`${event.sponsor_image.url}&w=364`"
        />
        <div
          v-if="hasTeaserImage && event.teaser_image.alt"
          class="event-teaser-image-text"
        >
          <span>{{ event.teaser_image.alt }}</span>
        </div>
      </b-col>
    </b-row>
    <b-row class="heading">
      <b-col :cols="$mq | mq({ sm: 12, md: 8 })">
        <h1>{{ $prismic.richTextAsPlain(event.title) }}</h1>
      </b-col>
    </b-row>
    <b-row class="details">
      <b-col class="main-column" :cols="$mq | mq({ sm: 12, md: 8 })">
        <div class="event">
          <cg-event-text class="text" :text="this.event.text" />
          <cg-event-links class="links" :links="this.event.links" />
          <cg-event-bands-main
            v-if="bandsMain.length > 0"
            class="main-bands"
            :bands="bandsMain"
          />
          <cg-event-bands-support
            v-if="bandsSupport.length > 0"
            class="support-bands"
            :bands="bandsSupport"
          />
          <cg-event-boilerplate
            class="boilerplate"
            :text="this.event.boilerplate"
          />
          <div class="images-wrapper">
            <cg-content-image class="image" :image="event.content_image" />
            <cg-content-image
              v-for="(band, index) in bandsMain"
              :key="index"
              :image="band.image"
              class="image"
            />
            <cg-content-image
              v-for="(band, index) in bandsSupport"
              :key="index"
              :image="band.image"
              class="image"
            />
          </div>
        </div>
      </b-col>
      <b-col class="info-column" :cols="$mq | mq({ sm: 12, md: 3 })">
        <cg-event-infos class="event-infos" :infos="eventInfos" />

        <div class="event-quicklinks">
          <div
            class="quicklink tickets"
            v-if="event.tickets.url && event.tickets.link_type === 'Web'"
          >
            <a :href="event.tickets.url">TICKETS</a>
          </div>
          <div
            class="quicklink fb"
            v-if="event.facebook.url && event.facebook.link_type === 'Web'"
          >
            <a :href="event.facebook.url">FACEBOOK EVENT</a>
          </div>
        </div>
        <div
          class="event-misc"
          v-html="$prismic.richTextAsHTML(this.event.misc)"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";

import CgContentImage from "./../shared/cg-content-image";
import CgEventText from "./shared/cg-event-text";
import CgEventInfos from "./shared/cg-event-infos";
import CgEventBandsMain from "./cg-event-bands-main";
import CgEventBandsSupport from "./cg-event-bands-support";
import CgEventBoilerplate from "./cg-event-boilerplate";
import CgEventLinks from "./cg-event-links";
import CgEventTeaserHeaderMobile from "./event-teaser/shared/cg-event-teaser-header-mobile";

export default {
  name: "CgEventDetail",

  components: {
    CgContentImage,
    CgEventBandsMain,
    CgEventBandsSupport,
    CgEventBoilerplate,
    CgEventInfos,
    CgEventLinks,
    CgEventText,
    CgEventTeaserHeaderMobile
  },

  props: {
    event: {
      default: undefined,
      type: [Object, Number]
    }
  },

  computed: {
    formatedDate() {
      return `${this.weekday}, ${this.date}`;
    },

    date() {
      return format(parseISO(this.event.datetime), "dd. MM.", { locale: de });
    },

    weekday() {
      return format(parseISO(this.event.datetime), "EEEE", {
        locale: de
      });
    },

    eventInfos() {
      // Add Date to Infolist
      let infos =
        this.$mq !== "sm"
          ? [{ info: this.formatedDate }, ...this.event.info_list]
          : [...this.event.info_list];
      return infos;
    },

    hasQuicklinks() {
      return this.event.facebook.url || this.event.tickets.url ? true : false;
    },

    bandsMain() {
      return this.event.bands.filter(band => band.role === "Main" && band.name);
    },
    bandsSupport() {
      return this.event.bands.filter(
        band => band.role === "Support" && band.name
      );
    },
    hasTeaserImage() {
      return !!this.event.teaser_image.url;
    },

    hasSponsorImage() {
      return !!this.event.sponsor_image.url;
    }
  }
};
</script>
<style lang="scss" scoped>
[id='event-detail'] {
  &.mobile {
    .main-column {
      margin-right: auto;
    }
    .info-column {
      max-width: 100%;
    }
    .event-teaser {
      margin-bottom: 16px;
      .sponsor-image {
        border-bottom: 1px solid #c1c1c1;
      }
    }

    h1 {
      font-size: 24px;
      line-height: 1;
      margin-bottom: 4px;
    }

    .text,
    .text /deep/ .event-text {
      text-align: left;
    }

    .event {
      & > .main-bands,
      .support-bands {
        margin-bottom: $baseline;
      }
    }
    .event-teaser-image-text {
      left: 0px;
      font-size: 10px;
    }

    .event-infos {
      margin-bottom: $baseline;
      /deep/ li {
        display: block;
        list-style: none;
        margin: 0 0 $baseline / 2 0;
        padding-bottom: $baseline / 2;
        border-bottom: 1px solid #000;

        font-size: 16px;
        text-align: left;
        color: #000000;
        &:after {
          content: '';
        }
        &:first-child {
          padding-top: $baseline / 2;
          border-top: 1px solid #000;
        }
      }
    }

    .quicklink {
      width: 200px;
      line-height: 23px;
      padding: 0 $baseline / 2 0 $baseline / 2;
      &:last-child {
        margin-bottom: $baseline;
      }

      a {
        font-size: 14.5px;
        letter-spacing: 2.03px;
      }
    }
  }
}

.main-column {
  margin-right: 5%;
}
.info-column {
  max-width: 20%;
}

h1 {
  font-family: 'Work Sans', sans-serif;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.93px;
}

.event {
  & > div,
  & > ul {
    margin-bottom: $baseline / 2;
  }

  & > .main-bands,
  .support-bands {
    margin-top: 24px;
  }
}

.teaser-image,
.sponsor-image {
  width: 100%;
  height: auto;
}

.event-teaser-image-text {
  position: absolute;
  top: 0;
  left: -16px;
  height: auto;
  transform-origin: 0 0;
  transform: rotate(-90deg) translateX(-100%) translateY(0%);
  font-size: 11px;
  color: #898989;
}
.event-boilerplate {
  margin-top: 24px;
}

.images-wrapper {
  margin-top: 24px;
  .image {
    margin-bottom: 24px;
  }
}

.links {
  padding: $baseline / 2 0 $baseline / 2 0;
  border-top: 1px dashed #818181;
  border-bottom: 1px dashed #818181;
}

.event-infos {
  margin: 0;
  padding: 0;
  margin-bottom: $baseline;
  /deep/ li {
    list-style: none;
    margin: 0 0 $baseline / 2 0;
    padding-bottom: $baseline / 2;
    border-bottom: 1px solid #000;
    font-size: 18px;
    letter-spacing: 0.32px;
    color: #000000;
  }
}

.quicklink {
  width: 100%;
  line-height: 18px;
  text-align: left;
  padding: 6px;
  margin-bottom: $baseline;

  &:last-child {
    margin-bottom: 24px;
  }

  a {
    width: 100%;
    display: block;
    font-family: Arial;
    font-size: 18px;
    letter-spacing: 1.44px;
    text-align: left;
    color: #ffffff;
    color: #ffffff;
  }
  &.fb {
    background-color: #3693d6;
  }
  &.tickets {
    background-color: #006bb7;
  }
}

.event-misc {
  font-size: 15px;
  font-style: italic;
  color: #898989;
}
</style>
