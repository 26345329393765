<template>
  <section id="misc" :class="{ mobile: $mq === 'sm' }">
    <b-container class="misc-content-container">
      <b-row>
        <b-col
          class="misc-content"
          :cols="$mq | mq({ sm: 12, md: 12, lg: 3 })"
          :offset="$mq | mq({ sm: 0, md: 0, lg: 9 })"
        >
          <a id="misc">
            <cg-misc-navigation class="misc-navigation" />
            <cg-contact class="misc-contact" />
            <cg-misc-legal class="misc-legal" />
          </a>
        </b-col>
      </b-row>
    </b-container>
    <div id="map" :style="{ backgroundImage: `url(${backgroundImage()})` }">
      <b-container class="map-text">
        <b-row>
          <b-col :cols="$mq | mq({ sm: 12, md: 12, lg: 9 })">
            <span>
              Der Eingang des Cafe Glocksee befindet sich auf dem Hof des UJZ
              Glocksee
            </span>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </section>
</template>

<script>
import { getImgPath } from "./../../../helpers";

import CgContact from "./../cg-contact";
import CgMiscNavigation from "./../cg-misc-navigation";
import CgMiscLegal from "./../cg-misc-legal";

export default {
  name: "CgMisc",

  components: {
    CgContact,
    CgMiscNavigation,
    CgMiscLegal
  },

  methods: {
    backgroundImage() {
      return getImgPath("footer-map.png");
    }
  }
};
</script>

<style lang="scss" scoped>
[id='misc'] {
  position: relative;
  border-top: 1px solid #000;
  background-color: #f5f7f9;
  &.mobile {
    border-top: 2px solid #000;
    font-size: 22px;
    background-color: #fff;
    margin-bottom: 80px;
    [id='map'] {
      position: relative;
      height: 314px;
      background-size: cover;
      background-position: -182px 0;
    }
    .misc-content-container {
      .row {
        height: auto;
      }
    }

    .misc-content {
      padding-top: 16px;
      padding-left: 40px;
      max-width: 300px;
    }

    .misc-legal {
      border-bottom: 0;
      padding-bottom: 24px;
    }
  }
}
[id='map'] {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center 80px;
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 560px;
  top: 0;
  left: 0;
}
.misc-content-container {
  .row {
    height: 912px;
  }
}

.misc-content {
  position: relative;
  z-index: 2;
  padding-top: 80px;
  background-color: #fff;
}

.misc-navigation {
  margin-bottom: 16px;
}
.misc-contact {
  margin-bottom: 8px;
}

.misc-legal,
.misc-contact {
  padding-bottom: $baseline / 2;
  border-bottom: 1px solid #000;
}

.map-text {
  margin-bottom: -20px;
  text-align: right;
  font-size: 13px;
  color: #808080;
  .mobile & {
    text-align: left;
    margin-bottom: -36px;
  }
}
</style>
