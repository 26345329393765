import Vue from "vue";
import Vuex from "vuex";
import {
  isPast,
  parseISO,
  endOfDay,
  isWithinInterval,
  addMonths
} from "date-fns";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    stageImage: undefined,
    stageSponsorImage: undefined,
    events: undefined,
    eventTypeFilter: undefined,
    eventDateFilter: undefined,
    documents: {},
    testState: undefined
  },

  getters: {
    getEvents(state, getters) {
      const events = getters.getDocumentsByType("event");
      if (events) {
        events.sort((a, b) => (a.data.datetime > b.data.datetime ? 1 : -1));
      }
      return events;
    },

    getFilteredEvents(state, getters) {
      const events = getters.getEvents;
      if (!events) return [];

      let filteredEvents = [...events];

      // filter only current events
      filteredEvents = filteredEvents.filter(event => {
        return !isPast(endOfDay(parseISO(event.data.datetime)));
      });

      // filter for event type
      if (state.eventTypeFilter) {
        filteredEvents = filteredEvents.filter(
          event => event.data.event_type === state.eventTypeFilter
        );
      }

      // filter for event dates
      if (state.eventDateFilter) {
        filteredEvents = filteredEvents.filter(event =>
          isWithinInterval(parseISO(event.data.datetime), {
            start: state.eventDateFilter,
            end: addMonths(state.eventDateFilter, 1)
          })
        );
      }

      return filteredEvents;
    },

    getEventByUID: (state, getters) => uid => {
      const events = getters.getEvents;
      const event = events
        ? events.find(event => event.uid === uid)
        : undefined;
      return event;
    },

    prevEventUID: (state, getters) => currentEventuid => {
      try {
        const events = getters.getEvents;
        const eventIndex = events
          ? events.findIndex(event => event.uid === currentEventuid)
          : undefined;
        return events[eventIndex - 1] &&
          !isPast(endOfDay(parseISO(events[eventIndex - 1].data.datetime)))
          ? events[eventIndex - 1].uid
          : false;
      } catch (e) {
        console.warn("Hell, no: ", e);
      }
    },

    nextEventUID: (state, getters) => currentEventuid => {
      try {
        const events = getters.getEvents;
        const eventIndex = events
          ? events.findIndex(event => event.uid === currentEventuid)
          : undefined;
        return events[eventIndex + 1] ? events[eventIndex + 1].uid : false;
      } catch (e) {
        console.error("Hell not again", e);
      }
    },

    getDocumentsByType: state => documentType => {
      const documents = state.testState
        ? state.testState.filter(document => document.type === documentType)
        : undefined;
      return documents;
    }
  },

  mutations: {
    SET_STAGE_IMAGE(state, stageImage) {
      state.stageImage = stageImage;
    },

    SET_STAGE_SPONSOR_IMAGE(state, stageSponsorImage) {
      state.stageSponsorImage = stageSponsorImage;
    },

    SET_EVENTS(state, events) {
      state.events = events;
    },

    SET_EVENT_TYPE_FILTER(state, eventType) {
      state.eventTypeFilter = eventType;
    },

    SET_EVENT_DATE_FILTER(state, eventType) {
      state.eventDateFilter = eventType;
    },

    SET_ALL_DOCUMENTS(state, documents) {
      state.testState = documents;
    }
  },

  actions: {
    GET_ALL_DOCUMENTS({ commit }) {
      this._vm.$prismic.client
        .query("", { pageSize : 100 })
        .then(response => {
          commit("SET_ALL_DOCUMENTS", response.results);
        })
        .catch(e => {
          console.log("Error fetching all stories", e);
        });
    },

    GET_STAGE_IMAGE({ commit }) {
      this._vm.$prismic.client
        .query(this._vm.$prismic.Predicates.at("document.type", "stage"), {
          orderings: "[my.stage.last_publication_date]"
        })
        .then(response => {
          commit("SET_STAGE_IMAGE", response.results[0].data.image);
        })
        .catch(e => {
          console.log("Error fetching Stage Image", e);
        });
    }
  }
});
