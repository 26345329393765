<template>
  <ul class="event-infos" v-if="infos.length > 0">
    <li v-for="(info, index) in infos" :key="index">
      <span v-if="info.info.length > 0">
        {{ info.info }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "CgEventInfos",

  props: {
    infos: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
li {
  font-family: 'Roboto Condensed', sans-serif;
  letter-spacing: 1px;
}
</style>
