<template>
  <span
    class="event-date"
    :class="{ sm: $mq === 'sm' }"
    v-html="formatedDate"
  />
</template>

<script>
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";

export default {
  name: "CgEventTeaserDate",

  props: {
    event: {
      type: Object,
      default: undefined
    }
  },

  computed: {
    formatedDate() {
      return `${this.date}&nbsp;&nbsp;|&nbsp;&nbsp;${this.weekday}`;
    },

    date() {
      return format(parseISO(this.event.datetime), "dd. MM.", { locale: de });
    },

    weekday() {
      return format(parseISO(this.event.datetime), "EEEE", {
        locale: de
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.event-date {
  font-size: 16px;
  letter-spacing: 0.98px;
  color: #010101;
  &.sm {
    font-size: 17.5px;
    font-stretch: normal;
    letter-spacing: 0.44px;
    text-align: left;
    color: #010101;
  }
}
</style>
