var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ul',{class:{ mobile: _vm.$mq === 'sm' }},[_c('li',{staticClass:"prev"},[_c('router-link',{attrs:{"disabled":!_vm.prevEventUID(_vm.uid),"event":_vm.prevEventUID(_vm.uid) ? 'click' : '',"to":{
              name: 'event',
              params: {
                uid: _vm.prevEventUID(_vm.uid),
                transitionName: 'slide-right',
              },
            }}},[_vm._v(" Vorherige")]),_vm._v(" | ")],1),_c('li',{staticClass:"programm"},[_vm._v(" | "),_c('router-link',{attrs:{"to":'/'}},[_vm._v("Programm")]),_vm._v(" | ")],1),_c('li',{staticClass:"next"},[_vm._v(" | "),_c('router-link',{attrs:{"disabled":!_vm.nextEventUID(_vm.uid),"event":_vm.nextEventUID(_vm.uid) ? 'click' : '',"to":{
              name: 'event',
              params: {
                uid: _vm.nextEventUID(_vm.uid),
                transitionName: 'slide-left',
              },
            }}},[_vm._v("Nächste")])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }