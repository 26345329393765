<template>
  <div id="app">
    <cg-header />
    <transition
      :name="transitionName"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <router-view :key="$route.fullPath" />
    </transition>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import CgHeader from "./components/cg-header";

const DEFAULT_TRANSITION = "slide-left";

export default {
  name: "AppPage",

  components: {
    CgHeader
  },

  data: () => ({
    prevHeight: 0,
    transitionName: DEFAULT_TRANSITION
  }),

  created() {
    this.GET_ALL_DOCUMENTS();

    this.$router.beforeEach((to, from, next) => {
      let transitionName =
        to.params.transitionName ||
        to.meta.transitionName ||
        from.meta.transitionName;

      // if (transitionName === "slide") {
      //   const toDepth = to.path.split("/").length;
      //   const fromDepth = from.path.split("/").length;
      //   transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      // }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  },
  update() {
    this.$nextTick(() => {
      if (this.$route.hash) {
        const el = document.getElementById("misc");
        el && el.scrollIntoView();
      }
    });
  },

  methods: {
    ...mapActions(["GET_ALL_DOCUMENTS"]),

    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>

<style lang="scss">
@media (max-width: 767px) {
  .container,
  .container-sm {
    max-width: 100%;
  }
}
h1 {
  font-family: 'Work Sans', sans-serif;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.93px;
}
</style>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>
