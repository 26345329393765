<template>
  <div id="stage-sponsor-wrapper">
    <b-container>
      <b-row class="stage-sponsor">
        <b-col :cols="$mq | mq({ sm: 12, md: 3 })">
          <img
            class="stage-sponsor-image"
            :src="`${stageSponsorImage.url}&w=280`"
            :alt="stageSponsorImage.alt ? stageSponsorImage.alt : ''"
          />
        </b-col>
        <b-col :cols="$mq | mq({ sm: 12, md: 8 })" class="mr-auto" />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CgStageSponsorImage",

  computed: {
    ...mapState(["stageSponsorImage"])
  }
};
</script>

<style lang="scss" scoped>
[id='stage-sponsor-wrapper'] {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.stage-sponsor {
  position: relative;
}
.stage-sponsor-image {
  height: auto;
  transform-origin: 100% 0%;
  transform: rotate(-90deg) translateX(0%) translateY(-280px);
}
</style>
