<template>
  <ul v-if="hasLinks" class="event-links">
    <template v-for="(link, index) in links">
      <li v-if="link.link_url && link.link_title" :key="index">
        <cg-event-link :link="link" />
      </li>
    </template>
  </ul>
</template>

<script>
import CgEventLink from "./cg-event-link";

export default {
  name: "CgEventLinks",

  components: {
    CgEventLink
  },

  props: {
    links: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    hasLinks() {
      return this.links && this.links.length > 0 && this.links[0].link_title
        ? true
        : false;
    }
  }
};
</script>

<style lang="scss" scoped>
.event-links {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
</style>
