<template>
  <div id="misc-legal" :class="{ mobile: $mq === 'sm' }">
    <router-link to="/imprint">Impressum</router-link>
    <span>|</span>
    <router-link to="/terms-and-conditions">AGB</router-link>
    <span>|</span>
    <router-link to="/privacy-policy">Datenschutz</router-link>
  </div>
</template>

<script>
export default {
  name: "CgMiscLegal"
};
</script>

<style lang="scss" scoped>
[id='misc-legal'] {
  display: flex;
  justify-content: space-around;
  font-size: 13px;
}

a {
  display: inline-block;
  position: relative;
  font-size: 13px;
  letter-spacing: 0.6px;
  color: #000000;
}

span {
  padding-left: $baseline / 4;
  padding-right: $baseline / 4;
}
</style>
