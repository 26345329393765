<template>
  <a
    v-if="link"
    :href="link.link_url.url"
    :title="link.link_title"
    :class="{ mobile: $mq === 'sm' }"
  >
    {{ link.link_title }}
  </a>
</template>

<script>
export default {
  name: "CgEventLink",

  props: {
    link: {
      type: Object,
      default: undefined
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  color: #5d8aa9;
  font-family: 'Roboto Condensed', sans-serif;
  letter-spacing: 1px;

  &.mobile {
    font-size: 18px;
  }
}
</style>
