<template>
  <div class="event-text">
    <template v-if="teaser">
      {{ processedText
      }}<router-link :to="`/event/${uid}`" class="more"
        >mehr&nbsp;>>></router-link
      >
    </template>
    <template v-else>
      <div class="event-text-rt" v-html="processedText" />
    </template>
  </div>
</template>

<script>
const TEASER_LENGTH = 350;
const TEASER_LENGTH_MOBILE = 250;

export default {
  name: "CgEventText",

  props: {
    text: {
      type: Array,
      default: () => []
    },

    teaser: {
      type: Boolean,
      default: false
    },

    uid: {
      type: String,
      default: null,
      required: true
    }
  },

  computed: {
    teaserLength() {
      return this.$mq === "sm" ? TEASER_LENGTH_MOBILE : TEASER_LENGTH;
    },

    processedText() {
      return this.teaser
        ? `${this.$prismic
            .richTextAsPlain(this.text)
            .substr(0, this.teaserLength)}....`
        : this.$prismic.richTextAsHTML(this.text);
    }
  }
};
</script>

<style lang="scss" scoped>
.event-text {
  color: #000;
}

.more {
  display: inline;
  text-align: left;
  color: #5d8aa9;
}
</style>
