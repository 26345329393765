import Vue from "vue";
import Router from "vue-router";
import Home from "./views/home.vue";
import Event from "./views/event.vue";
import Content from "./views/content.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { transitionName: "slide-right" }
    },
    {
      path: "/imprint",
      name: "imprint",
      component: Content,
      props: {
        documentType: "imprint"
      }
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: Content,
      props: {
        documentType: "privacy_policy"
      }
    },
    {
      path: "/terms-and-conditions",
      name: "terms-and-conditions",
      component: Content,
      props: {
        documentType: "terms_and_conditions"
      }
    },
    {
      path: "/event/:uid",
      name: "event",
      component: Event,
      meta: { transitionName: "slide-left" },
      props: true
    }
  ]
});
