<template>
  <div id="cg-page-imprint">
    <b-container :class="{ mobile: $mq === 'sm' }">
      <b-row class="heading">
        <b-col :cols="$mq | mq({ sm: 12, md: 8 })">
          <h1 v-if="title">{{ title }}</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="content"
          v-html="content"
          v-if="content"
          :cols="$mq | mq({ sm: 12, md: 8 })"
        />
      </b-row>
    </b-container>
    <cg-misc />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CgMisc from "./../components/misc/shared/cg-misc";

export default {
  name: "Content",

  components: {
    CgMisc
  },

  props: {
    documentType: {
      type: String,
      reuired: true
    }
  },

  computed: {
    ...mapGetters(["getDocumentsByType"]),

    document() {
      return this.getDocumentsByType(this.documentType);
    },

    title() {
      return (
        this.$prismic.richTextAsPlain(
          this.document && this.document[0]["data"]["title"]
        ) || null
      );
    },
    content() {
      return (
        this.$prismic.richTextAsHTML(
          this.document && this.document[0]["data"]["text"]
        ) || null
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
