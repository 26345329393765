<template>
  <p id="misc-contact" :class="{ mobile: $mq === 'sm' }">
    Cafe Glocksee<br />
    Glockseestr. 35<br />
    30169 Hannover<br />
    <a href="mailto:mail@cafe-glocksee.de">mail@cafe-glocksee.de</a><br />
    <a href="tel:+495111614712">0511 - 161 47 12</a><br />
  </p>
</template>

<script>
export default {
  name: "CgContact"
};
</script>

<style lang="scss" scoped>
[id='misc-contact'] {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.71px;
  line-height: 1.4;
  a {
    color: #000;
  }
}
</style>
