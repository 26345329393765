<template>
  <div class="main-nav-wrapper" :class="{ mobile: $mq === 'sm' }">
    <b-container class="main-nav-inner" :class="{ mobile: $mq === 'sm' }">
      <b-navbar
        class="main-nav"
        :class="{ mobile: $mq === 'sm' }"
        toggleable="md"
      >
        <b-navbar-toggle class="ml-auto" target="nav"></b-navbar-toggle>

        <b-collapse is-nav id="nav" :class="{ mobile: $mq === 'sm' }">
          <b-navbar-nav class="main-nav-navbar ml-auto mr-auto">
            <b-nav-item to="/">
              Programm
              <cg-event-filter v-if="$mq === 'sm'" />
            </b-nav-item>
            <span class="separator">|</span>
            <b-nav-item :href="ticketUrl" target="_blank"> Tickets </b-nav-item>
            <span class="separator">|</span>
            <b-nav-item @click="jumpToMisc">Kontakt</b-nav-item>
            <span class="separator">|</span>
            <b-nav-item @click="jumpToMisc">Sonstiges</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>
import { TICKET_URL } from "./../constants";
import CgEventFilter from "./event/cg-event-filter.vue";

export default {
  name: "CgNav",

  components: {
    CgEventFilter
  },

  data: () => ({
    ticketUrl: TICKET_URL
  }),

  methods: {
    jumpToMisc() {
      const el = document.getElementById("misc");
      el && el.scrollIntoView();
    }
  }
};
</script>
<style lang="scss" scoped>
.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.main-nav-wrapper {
  width: 100%;
  margin-top: 0;
  padding: 0;
  background-color: #fff;
  opacity: 0.8;
  color: #000;
  &.mobile {
    background-color: transparent;
    opacity: 1;
    border-bottom: 1px solid #000;
  }
}

.main-nav-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  .mobile & {
    display: block;
    justify-content: left;
  }
}

.main-nav {
  padding: 0;
  &.mobile {
    .main-nav-navbar {
      margin-bottom: $baseline;
    }

    .nav-item,
    .nav-item a {
      font-size: 22px;
      font-weight: 700;
      letter-spacing: 0.77px;
      text-align: left;
    }
    .separator {
      display: none;
    }
  }
}

[id='nav'] {
  margin-top: -2px;
  &.mobile {
    padding-left: $baseline * 2;
    padding-right: $baseline * 2;

    .navbar-nav {
      align-items: flex-start;
    }
  }
}

.navbar-toggler {
  border: none;
}

.navbar-nav {
  align-items: center;
}

.nav-item,
.nav-item a {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.19px;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
}
.separator {
  justify-content: center;
}
</style>
