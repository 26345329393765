<template>
  <div class="event-bands" :class="{ mobile: $mq === 'sm' }">
    <div class="band" v-for="(band, index) in bands" :key="index">
      <h3>Support: {{ band.name }}</h3>
      <cg-event-text class="text" :text="band.description" />
      <cg-event-links class="links" :links="links(band)" />
    </div>
  </div>
</template>

<script>
import CgEventText from "./shared/cg-event-text";
import CgEventLinks from "./cg-event-links";

export default {
  name: "CgEventBandsSupport",

  components: {
    CgEventLinks,
    CgEventText
  },

  props: {
    bands: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    links(band) {
      let links = [];
      for (let i = 1; i < 3; i++) {
        const key_title = `support_link_title_${i}`;
        const key_url = `support_link_url_${i}`;
        if (band[key_title]) {
          links.push({
            link_title: band[key_title],
            link_url: band[key_url]
          });
        }
      }

      return links;
    }
  }
};
</script>

<style lang="scss" scoped>
.event-bands {
  &.mobile {
    .band {
      margin-bottom: $baseline;
      h3 {
        font-size: 18px;
      }
    }

    .text,
    .text /deep/ .event-text {
      text-align: left;
    }
  }
}

.band {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 8px;
  }
}

h3 {
  font-family: 'Work Sans', sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.text {
  margin-bottom: $baseline / 2;
}

.image {
  margin-bottom: $baseline / 2;
}

.links {
  padding: $baseline / 2 0 $baseline / 2 0;
  border-top: 1px dashed #818181;
  border-bottom: 1px dashed #818181;
  margin-bottom: $baseline / 2;
}
</style>
