<template>
  <div
    :key="bgImageUrl"
    v-if="bgImageUrl"
    class="stage-image"
    :style="{ backgroundImage: `url(${bgImageUrl})` }"
  >
    <b-container
      class="stage-image-text-wrapper"
      :class="{ mobile: $mq === 'sm' || $mq === 'md' }"
    >
      <b-row>
        <b-col cols="12" class="ml-auto mr-auto">
          <div v-if="stageImage.alt" class="stage-image-text">
            <span>{{ stageImage.alt }}</span>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CgStageImage",

  computed: {
    ...mapState(["stageImage"]),

    bgImageUrl() {
      if (this.stageImage) {
        return this.stageImage.url;
      } else {
        return undefined;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.stage-image-text-wrapper {
  position: relative;
  height: 280px;
  .col-12 {
    height: 280px;
  }
}

.stage-image {
  width: 100%;
  height: 100%;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.stage-image-text {
  color: #808080;
  position: absolute;
  bottom: 20px;
  right: 0px;
  transform-origin: 0 0;
  transform: rotate(270deg) translateX(0%) translateY(100%);
  font-size: 12px;
  text-align: right;
  display: inline;
}
</style>
