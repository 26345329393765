<template>
  <b-container id="event-filter" :class="{ mobile: $mq === 'sm' }">
    <div class="filter-group">
      <button
        class="filter"
        :class="{ selected: !hasFilters }"
        @click="resetFilters()"
      >
        Alle Termine
      </button>
      <span class="separator"> | </span>
      <template v-for="(eventTypeFilter, index) in eventTypeFilters">
        <button
          :key="`b-${index}`"
          class="filter"
          :class="{ selected: eventTypeFilterIsSelected(eventTypeFilter.key) }"
          @click="SET_EVENT_TYPE_FILTER(eventTypeFilter.key)"
        >
          {{ eventTypeFilter.value }}
        </button>
        <span class="separator" :key="`bs-${index}`"> | </span>
      </template>
      <template v-for="(eventDateFilter, index) in eventDateFilters">
        <button
          :key="`c-${index}`"
          class="filter"
          :class="{ selected: eventDateFilterIsSelected(eventDateFilter.key) }"
          @click="SET_EVENT_DATE_FILTER(eventDateFilter.key)"
        >
          {{ eventDateFilter.value }}
        </button>
        <span
          class="separator"
          :key="`cs-${index}`"
          v-if="index < eventDateFilters.length - 1"
        >
          |
        </span>
      </template>
    </div>
  </b-container>
</template>

<script>
import { addMonths, format, startOfMonth } from "date-fns";
import { de } from "date-fns/locale";
import { mapMutations, mapState } from "vuex";

export default {
  name: "CgEventFilter",

  data: () => ({
    thisMonth: startOfMonth(new Date()),
    eventDateFilters: [],
    // eventTypeFilters: [
    //   { key: "Konzert", value: "Konzerte" },
    //   { key: "Party", value: "Parties" },
    //   { key: "Sonstiges", value: "Sonstige" }
    // ]
    eventTypeFilters: []
  }),

  computed: {
    ...mapState(["eventTypeFilter", "eventDateFilter"]),

    hasFilters() {
      return (
        this.eventTypeFilter !== undefined || this.eventDateFilter !== undefined
      );
    }
  },

  methods: {
    ...mapMutations(["SET_EVENT_TYPE_FILTER", "SET_EVENT_DATE_FILTER"]),

    eventTypeFilterIsSelected(value) {
      return value === this.eventTypeFilter;
    },

    eventDateFilterIsSelected(value) {
      return value === this.eventDateFilter;
    },

    resetFilters() {
      this.SET_EVENT_TYPE_FILTER(undefined);
      this.SET_EVENT_DATE_FILTER(undefined);
    }
  },
  created() {
    const numEventDateFilters = 6;
    for (let i = 0; i < numEventDateFilters; i++) {
      const date = addMonths(this.thisMonth, i);
      this.eventDateFilters.push({
        key: date,
        value: format(date, "MMM yy", { locale: de })
      });
    }
  }
};
</script>

<style lang="scss" scoped>
div[id='event-filter'] {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 6px;

  &.mobile {
    padding-top: $baseline / 2;
    border-top: 1px solid #000;
    padding-left: 0;
    padding-bottom: $baseline / 2;
    border-bottom: 1px solid #000;

    display: block;
    margin-bottom: 0;

    .filter-group-first {
      margin-left: 0;
    }
    .filter-group {
      margin-left: 0;
      button {
        padding-left: 0;
      }
    }
    .filter {
      display: block;

      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.77px;
      text-align: left;

      &.selected {
        color: #000;
        transform: none;
      }
    }

    .separator {
      display: none;
    }
  }
}

.filter-group {
  margin-left: 33.5555%;
  &:last-child {
    margin-right: 0;
  }
}

.filter,
.separator {
  font-family: 'Roboto Condensed', sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #ccc;
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  transition: all 0.2s;
  &:focus {
    outline: 0;
  }
  &.selected {
    color: #000;
  }
}

.filter {
  padding: 2px $baseline / 4 2px $baseline / 4;
}
</style>
