<template>
  <nav id="secondary-navigation" :class="{ mobile: $mq !== 'sm' }">
    <ul>
      <!-- <li><a>Newsletter</a></li> -->
      <li>
        <router-link to="/"> Programm </router-link>
      </li>
      <li>
        <a :href="ticketUrl" target="_blank"> Tickets </a>
      </li>
      <li>
        <a href="https://www.facebook.com/CafeGlocksee" target="_blank">
          Facebook
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/cafe_glocksee" target="_blank">
          INSTAGRAM
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { TICKET_URL } from "./../../constants";

export default {
  name: "CgMiscNavigation",
  data: () => ({
    ticketUrl: TICKET_URL
  })
};
</script>

<style lang="scss" scoped>
[id="secondary-navigation"] {
  &.mobile {
  }
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
    padding-bottom: $baseline / 2;
    margin-bottom: $baseline / 2;
    border-bottom: 1px #000 solid;
    a {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 1.76px;
      color: #000;
      text-transform: uppercase;
    }
  }
}
</style>
