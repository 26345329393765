<template>
  <b-container id="event-teaser-list">
    <ul class="list-wrapper">
      <li
        v-for="event in getFilteredEvents"
        :key="event.uid"
        class="list-complete-item"
      >
        <cg-event-teaser :event="event.data" :uid="event.uid" />
      </li>
    </ul>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CgEventTeaser from "./cg-event-teaser";

export default {
  name: "EventTeaserList",

  components: {
    CgEventTeaser
  },

  computed: {
    ...mapGetters(["getFilteredEvents"])
  }
};
</script>

<style lang="scss" scoped>
#event-teaser-list {
  position: relative;
}

.list-wrapper {
  margin: 0;
  padding: 0;
}

.list-complete-item {
  background-color: #fff;
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition: all 0.3s;
  // display: inline-block;
  // margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>
