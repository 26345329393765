<template>
  <div id="home">
    <cg-event-filter v-if="$mq !== 'sm'" />
    <cg-event-teaser-list />
    <cg-misc />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import CgEventFilter from "../components/event/cg-event-filter";
import CgEventTeaserList from "../components/event/event-teaser/cg-event-teaser-list";
import CgMisc from "./../components/misc/shared/cg-misc";

export default {
  name: "home",

  components: {
    CgEventFilter,
    CgEventTeaserList,
    CgMisc
  },

  methods: {
    ...mapActions(["GET_STAGE_IMAGE"]),
    ...mapMutations(["SET_STAGE_SPONSOR_IMAGE"])
  },

  created() {
    this.GET_STAGE_IMAGE();
  }
};
</script>

<style lang="scss" scoped></style>
