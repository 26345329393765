<template>
  <header :class="{ mobile: $mq === 'sm' || $mq === 'md' }">
    <b-container
      class="logo-wrapper"
      :class="{ mobile: $mq === 'sm' || $mq === 'md' }"
    >
      <b-row>
        <b-col cols="12" class="logo ml-auto mr-auto">
          <cg-logo />
        </b-col>
      </b-row>
    </b-container>
    <div id="stage" v-if="$mq !== 'sm' && $mq !== 'md'">
      <transition name="slide-fade">
        <cg-stage-image />
      </transition>
      <transition name="slide-fade" v-if="stageSponsorImage">
        <cg-stage-sponsor-image />
      </transition>
    </div>
    <cg-nav id="main-nav" :class="{ mobile: $mq === 'sm' || $mq === 'md' }" />
  </header>
</template>

<script>
import { mapState } from "vuex";

import CgLogo from "./cg-logo";
import CgNav from "./cg-nav";
import CgStageImage from "./cg-stage-image";
import CgStageSponsorImage from "./cg-stage-sponsor-image";

export default {
  name: "CgHeader",

  components: {
    CgLogo,
    CgNav,
    CgStageImage,
    CgStageSponsorImage
  },

  computed: {
    ...mapState(["stageSponsorImage"])
  }
};
</script>

<style lang="scss" scoped>
header {
  margin-bottom: 48px;
  &.mobile {
    margin-bottom: $baseline;
  }
}

.logo-wrapper {
  &.mobile {
    background-color: #000;
    height: 194px;
  }
}

.logo {
  text-align: center;
}

[id='stage'] {
  position: relative;
  height: 280px;
  background-color: #000;
  overflow: hidden;
}

[id='main-nav'] {
  margin-top: -30px;
  height: 30px;
  // position: absolute;
  // bottom: 0;
  &.mobile {
    margin-top: 0;
    height: auto;
  }
}

.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: scale(1.4);
  opacity: 0;
}
</style>
