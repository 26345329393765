<template>
  <div class="event-quicklinks" :class="{ mobile: $mq === 'sm' }">
    <div
      class="quicklink fb"
      v-if="event.facebook.url && event.facebook.link_type === 'Web'"
    >
      <a :href="event.facebook.url">FACEBOOK</a>
    </div>
    <div
      class="quicklink tickets"
      v-if="event.tickets.url && event.tickets.link_type === 'Web'"
    >
      <a :href="event.tickets.url">TICKETS</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CgEventTeaserQuicklinks",

  props: {
    event: {
      type: Object,
      default: undefined
    }
  }
};
</script>

<style lang="scss" scoped>
.event-quicklinks {
  display: flex;
  align-items: center;
}
.quicklink {
  width: 82px;
  line-height: 18px;
  text-align: center;
  margin-right: $baseline / 2;
  margin-bottom: -6px;
  &:last-child {
    margin-right: 0;
  }

  a {
    font-family: Arial;
    color: #ffffff;
    font-size: 10px;
    letter-spacing: 1.44px;
  }

  &.fb {
    background-color: #3693d6;
  }
  &.tickets {
    background-color: #006bb7;
  }

  .event-quicklinks.mobile & {
    width: 102px;
    line-height: unset;
    a {
      font-size: 14.5px;
      letter-spacing: 1.81px;
    }
  }
}
</style>
